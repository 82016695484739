<template>
  <div class="body">
    <section class="banner">
      <h2>加入<strong>橙仕</strong></h2>
      <p class="slogan">无畏挑战  极速成长</p>
      <p>橙仕集团给员工提供全方位无微不至的关怀，<br class="mShow" />伴随每一位矩阵人的幸福工作和生活<br />各地优越的人才补贴项目、领先的福利体系，<br class="mShow" />矩阵愿与每一位矩阵人共同成长</p>
    </section>

    <section class="box">
      <h3 class="title"><strong>橙仕集团</strong>社会招聘</h3>
      <ul class="jobList">
        <li class="item" v-for="(item, index) in list" :key="index">
          <router-link :to="`join/${item.id}`" target="_blank">
            <h3>{{item.title.rendered}}</h3>
            <p>{{item.metadata[0]}}</p>
            <span class="btnMore">查看职位</span>
          </router-link>
        </li>
        <li class="itemFill"></li>
        <li class="itemFill"></li>
      </ul>
      <div class="ft" v-if="totalPage > page">
        <span class="btnMore" @click="getList(page + 1)">{{loading ? '加载中…' : '查看更多'}}</span>
      </div>
    </section>

    <section class="scene" v-if="siteType === 'juzhen'">
      <div class="summary">
        <h3><strong>矩阵</strong><span>@</span>上海</h3>
        <p>上海市杨浦区政恒路66号凯迪立方大厦2号楼8楼</p>
      </div>
      <div class="preview">
        <img class="ani"
          src="https://alihtml.juzhen.cn/juzhen/assets/img/join/map.jpg"
          width="798" height="562"
          alt="矩阵@上海"
        />
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Jobs',
  data() {
    return {
      list: [],
      loading: true,
      totalPage: 1,
      page: 0,
    }
  },
  watch: {
    $route(val) {
      const { page } = val.query;
      const query = {
        page: Math.max(page, 1) || 1,
      };

      this.query = query;
      this.getList(query.page);
    }
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList(page = 1) {
      const perPage = 6;
      this.loading = true;

      this.axios({
        url: `posts?categories=11&per_page=${perPage}&page=${page}`
      }).then((res) => {
        const { data, pagination } = res;
        const { pages } = pagination;

        if (page === 1) {
          this.list = data;
        } else {
          this.list = [
            ...this.list,
            ...data,
          ]
        }
        
        this.loading = false;
        this.page = page;
        this.totalPage = parseInt(pages) || 0;
      });
    }
  }
};
</script>

<style scoped lang="scss">
.banner {
  padding-top: 270px;
  height: 1000px;
  text-align: center;
  color: #fff;
  font-size: 20px;
  line-height: 30px;
  background-color: #e83e0b;
  background-image: 
    url(https://alihtml.juzhen.cn/juzhen/assets/img/join/banner.jpg),
    linear-gradient(180deg, #e83e0b 0, #e83e0b 760px, #222226 760px);
  background-repeat: no-repeat, repeat;
  background-position: 50% 100%, 50%;
  background-size: 1500px 700px, 100%;

  h2 {
    font-size: 90px;
    line-height: 120px;
    color: #fff;
  }

  .slogan {
    margin-bottom: 400px;
    font-size: 40px;
    line-height: 60px;
  }
}

.box {
  position: relative;
  margin: 0 auto;
  padding: 130px 0 72px;
  max-width: 1160px;

  & + .box {
    padding-top: 120px;

    &::before {
      position: absolute;
      left: 50%;
      top: 0;
      width: calc(100vw - 60px);
      min-width: calc(100% - 30px);
      max-width: 1920px;
      height: 1px;
      content: '';
      transform: translateX(-50%);
      background-color: #000;
    }
  }

  .title {
    font-size: 50px;
    line-height: 70px;
    text-align: center;
  }

  .ft {
    margin: 0 0 40px;
    text-align: center;
  }

  .btnMore {
    position: relative;
    display: inline-block;
    vertical-align: top;
    padding: 0 46px;
    font-size: 22px;
    line-height: 60px;
    border-radius: 3px;
    cursor: pointer;

    &:hover {
      background-color: #efefef;
    }

    &::after {
      position: absolute;
      top: -50%;
      left: -50%;
      content: '';
      width: 200%;
      height: 200%;
      border: 3px solid #000;
      border-radius: 6px;
      transform: scale(.5);
    }
  }
}

.jobList {
  display: flex;
  margin-top: 80px;
  flex-wrap: wrap;
  color: #333131;
  min-height: 200px;

  .item, .itemFill {
    flex: 1;
    padding: 0 30px;
    min-width: 30%;
    max-width: 385px;
  }

  .item {
    margin: 0 0 112px;
    position: relative;
    font-size: 18px;
    line-height: 28px;
    text-align: center;

    a {
      color: #000;
    }

    h3 {
      font-size: 24px;
      line-height: 30px;
      font-weight: bold;
    }
    
    .btnMore {
      margin-top: 20px;
      padding: 0 26px;
      font-size: 20px;
      line-height: 48px;
    }
  }
}

.scene {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin: 20px auto 80px;
  max-width: 1080px;

  .summary {
    margin-left: 60px;
    width: 240px;
    font-size: 20px;
    line-height: 30px;

    h3 {
      font-size: 50px;
      line-height: 60px;

      span {
        font-size: 30px;
      }
    }
  }

  .preview {
    flex: 1;

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
}
@media only screen and (max-width: 500px) {
  .banner {
    padding-top: 190px;
    height: 640px;
    font-size: 12px;
    line-height: 16px;
    background: #e83e0b url(https://alihtml.juzhen.cn/juzhen/assets/img/join/m/banner.jpg) no-repeat 50% 100%;
    background-size: contain;

    h2 {
      font-size: 45px;
      line-height: 63px;
    }

    .slogan {
      margin-bottom: 230px;
      font-size: 20px;
      line-height: 32px;
    }
  }

  .box {
    padding: 42px 0;

    & + .box {
      padding-top: 42px;

      &::before {
        width: 94%;
      }
    }

    .title {
      font-size: 25px;
      line-height: 35px;
    }

    .ft {
      margin: 0;
    }

    .btnMore {
      padding: 0 30px;
      font-size: 14px;
      line-height: 38px;

      &::after {
        border-width: 2px;
      }
    }
  }

  .jobList {
    margin-top: 42px;

    .item, .itemFill {
      padding: 0 12px;
      min-width: 40%;
    }

    .item {
      margin: 0 0 55px;
      font-size: 12px;
      line-height: 16px;

      h3 {
        font-size: 14px;
        line-height: 18px;
      }
      
      .btnMore {
        margin-top: 12px;
        padding: 0 12px;
        font-size: 12px;
        line-height: 24px;
      }
    }
  }

  .scene {
    margin: 0 24px 36px;
    flex-direction: column-reverse;

    .summary {
      margin: 12px 0 0;
      width: 100%;
      font-size: 12px;
      line-height: 16px;

      h3 {
        font-size: 25px;
        line-height: 31px;
      }
    }
  }
}
</style>
